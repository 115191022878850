<template>
  <div class="page-container">
    <StepCounter v-if="onboardingConfig.steps" :steps="onboardingConfig.steps" />
    <DetailsForm @input="emittedValues => handleFormSubmit(emittedValues)" @skip-form="handleSkip" :isSubmitting="isSubmitting" :loadingPercentage="loadingPercentage" />
  </div>
</template>

<script>
import { stepCounterGetters, SET_STEP_COUNTER_LIST, SET_ACTIVE_STEP_INDEX } from '../../../../store/modules/goalsStepCounter/routines'
import { onboardingGetters } from '@/store/modules/onboarding/routines'
import { formatDynamicFormPayload } from '../../formatPayloads'
import { authGetters } from '@/store/modules/auth/routines'
import DetailsForm from '../../components/DetailsForm.vue'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import StepCounter from '@/components/StepCounter.vue'
import deepLinking from '@/mixins/deepLinking'
import { mapActions, mapGetters } from 'vuex'
import onboardingConfig from '../../config'
import { saveForm } from '@/api/goals'
import { tenantGetters } from '@/store/modules/tenant/routines'
import { getRecommendationsList } from '@product/api/opportunity'
import { companyGetters } from '@/store/modules/company/routines'
import { formMetaDataGetters, updateFormFileToUploadRoutine } from '@/store/modules/formMetaData/routines'
import { uploadFormFile } from '@/utils/utils'
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'details-page',
  mixins: [amplitudeTracking, deepLinking],
  components: { StepCounter, DetailsForm },
  data() {
    return {
      isSubmitting: false,
      loadingPercentage: 0
    }
  },
  async mounted() {
    this.trackPageView()
    if (!this.getStartedFormData) this.$router.push({ name: 'stage' })
    this.setActiveIndex(1)
  },
  computed: {
    ...mapGetters({
      getStartedFormData: onboardingGetters.GET_STARTED_FORM_DATA,
      formFileToUpload: formMetaDataGetters.FORM_FILE_TO_UPLOAD,
      stepCounterList: stepCounterGetters.STEP_COUNTER_LIST,
      activeStepIndex: stepCounterGetters.ACTIVE_STEP_INDEX,
      isInvitedUser: authGetters.IS_INVITED_USER,
      stageGoal: onboardingGetters.STAGE_GOAL,
      user: authGetters.USER,
      tenantName: tenantGetters.TENANT_NAME,
      companyId: companyGetters.COMPANY_ID
    }),
    onboardingConfig() {
      return onboardingConfig
    }
  },
  methods: {
    ...mapActions({
      setStepCounterList: SET_STEP_COUNTER_LIST,
      setActiveIndex: SET_ACTIVE_STEP_INDEX,
      setFormFileToUpload: updateFormFileToUploadRoutine.TRIGGER
    }),
    async redirectHandler() {
      this.handleDeepLinkRedirect()
      if (this.tenantName === 'canada' || this.tenantName === 'aus') {
        const productType = this.getStartedFormData.productType
        if (productType === 'Grant') {
          const { data } = await getRecommendationsList(this.companyId, 'findGrant')
          if (data) {
            this.$router.push({ name: 'grant-matches' })
          }
        } else if (productType === 'Equity') {
          this.$router.push({ name: 'equity-matches' })
        } else {
          this.$router.push({ name: 'loan-matches' })
        }
      } else {
        this.$router.push({ name: 'matches-overview' })
      }
    },
    async handleFormSubmit(formData) {
      this.isSubmitting = true
      try {
        // upload any files, updating the formData[field] with the file name and response documentId
        if (this.formFileToUpload) {
          const { documentId } = await uploadFormFile(this.companyId, this.formFileToUpload, this.formFileToUpload?.fileName)
          formData.fields[this.formFileToUpload.fieldName] = JSON.stringify({
            name: this.formFileToUpload.fileName,
            documentId: documentId
          })
          this.setFormFileToUpload(null)
        }
        const payload = formatDynamicFormPayload(formData.fields, formData.fieldIds, formData.sectionId, true)
        this.loadingPercentage = 70
        await saveForm(this.user.companies[0].companyId, formData.formId, payload)
        this.redirectHandler()
      } catch (error) {
        this.isSubmitting = false
        this.loadingPercentage = 0
        console.log(error)
      }
    },
    handleSkip() {
      this.redirectHandler()
    },
    trackPageView() {
      this.$ma.trackEvent(amplitudeEvents.pageViews.DETAILS_PAGE)
      this.trackPageViewWithUtmParams('Details Page')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
