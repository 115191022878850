<template>
  <div class="wrapper">
    <div class="content">
      <div class="loader-container" v-if="loading">
        <div>
          <Spinner :size="64" :loader="true" color />
        </div>
      </div>
      <div v-else>
        <div class="text-container">
          <h3>{{ formName }} funding requirements</h3>
          <div class="description-container">
            {{ config.detailsPage.description }}
          </div>
        </div>
        <div>
          <DynamicForm
            isNewOnboardingJourney
            :isFormSkippable="isSkippable"
            @previous-click="() => handlePreviousClick()"
            @submit="event => handleSubmit(event)"
            @skip-form="handleSkip"
            v-if="formFields"
            :form="formFields"
            :loading="loading"
            :submitState="{ loading: isSubmitting, loadingPercentage: loadingPercentage }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onboardingGetters } from '@/store/modules/onboarding/routines'
import { getFormStructure, getFormDetailsSection } from '@/api/goals'
import DynamicForm from '@/components/dynamicForms/DynamicForm.vue'
import { toastDefaultOptions } from '../../../config/vue-toast'
import { getFieldIds, prepopulateFields } from '../helpers'
import { authGetters } from '@/store/modules/auth/routines'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import { onlyNumbers } from '@/utils/currency'
import deepLinking from '@/mixins/deepLinking'
import Spinner from '@/components/Spinner'
import onboardingConfig from '../config'
import { mapGetters } from 'vuex'

export default {
  name: 'details-form',
  mixins: [amplitudeTracking, deepLinking],
  components: { DynamicForm, Spinner },
  props: { loadingPercentage: { required: true, type: Number }, isSubmitting: { required: true, type: Boolean } },
  data() {
    return {
      loading: null,
      form: null,
      formFields: null,
      formName: ''
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchFormDetailsSection()
    if (this.user) {
      this.initialiseForm()
    }
    this.loading = false
  },
  computed: {
    ...mapGetters({
      getStartedFormData: onboardingGetters.GET_STARTED_FORM_DATA,
      stageGoal: onboardingGetters.STAGE_GOAL,
      user: authGetters.USER
    }),
    config() {
      return onboardingConfig
    },
    isSkippable() {
      return this.getStartedFormData.skipDetailsPage
    }
  },
  methods: {
    async initialiseForm() {
      const { data } = await getFormStructure(this.user.companies[0].companyId, this.getStartedFormData.dynamic.formId)
      this.formFields = prepopulateFields(data, this.formFields)
    },
    formatValuesForPayload(values) {
      // handles removing of special chars from string int values
      const finalValues = {}
      for (const key of Object.keys(values)) {
        if (this.form.formSection.formFields.find(field => field.name === key).dataType === 'integer') {
          finalValues[key] = onlyNumbers(values[key])
        } else {
          finalValues[key] = values[key]
        }
      }
      return finalValues
    },
    handleSubmit(values) {
      const fields = this.formatValuesForPayload(values)
      this.$emit('input', {
        fields,
        fieldIds: getFieldIds(this.formFields),
        sectionId: this.form.formSection.formSectionId,
        formId: this.form.formSection.formId
      })
    },
    handleSkip() {
      this.$emit('skip-form')
    },
    async fetchFormDetailsSection() {
      let formName = this.getStartedFormData.fundingReason
      // form is fetched based on stage options answer if they chose 'Other' for specific funding reason
      if (formName === 'Other') {
        formName = this.config.detailsPage.formRedirectOnOtherFundingReason[this.stageGoal || 'notSure']
      }
      try {
        const { data } = await getFormDetailsSection(formName)
        this.form = data
        this.formName = data.formSection.displayName
        this.formFields = data.formSection.formFields
      } catch (error) {
        this.$toasted.show('Something went wrong.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.wrapper {
  width: 100%;
}

.text-container {
  padding: 0 50px;
  margin-bottom: 48px;

  h3 {
    font-size: 28px;
  }
  .description-container {
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 1600px;
  justify-content: center;
  .loader-container {
    display: flex;
    justify-self: center;
    align-self: center;
  }
}
@media only screen and (max-width: 600px) {
  .text-container {
    padding: 0 0px;
    margin-bottom: 24px;
  }
  .content {
    padding: 8px;
  }
}
@media only screen and (max-width: 830px) {
  .text-container {
    padding: 0 16px;
    margin-bottom: 8px;
  }
}
</style>
